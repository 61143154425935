import React from "react"
import Socials from "./Socials"

export default function Footer() {
  const contactEmail = "igobyjude@gmail.com"
  return (
    <footer
      className={
        "md:flex md:flex-wrap md:flex-row sm:flex-col justify-between my-4"
      }
    >
      <div className="my-2 text-center sm:w-screen md:flex-1">
        For bookings contact: {contactEmail}
      </div>
      <div className="my-2 text-center sm:w-screen md:flex-1">
        Copyright igobyjude {new Date().getFullYear()}
      </div>
      <Socials
        classes="my-2 sm:w-screen md:flex-1 sm:items-center md:items-left"
        subClasses="items-center text-center justify-center"
      />
    </footer>
  )
}
