import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Seo from "./Seo"

export default function Layout({ children, activePage }) {
  return (
    <div
      style={{
        backgroundColor: "lightgrey",
      }}
      className="flex flex-col justify-between w-full h-full application"
    >
      <Seo />
      <Header activePage={activePage} />
      <div>{children}</div>
      <Footer />
    </div>
  )
}
