import React from "react"
import Navbar from "./Navbar"

export default function Layout({ activePage }) {
  return (
    <header style={{ width: "90vw", margin: "auto" }}>
      <Navbar activePage={activePage} />
    </header>
  )
}
