import React from "react"

export default function SafeAnchor({ linkUrl, classes, children, subClasses }) {
  return (
    <a
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={classes}
    >
      <div className={`${classes} ${subClasses ? subClasses : ""}`}>
        {children}
      </div>
    </a>
  )
}
