import React from "react"
// import { Link } from "gatsby"
// import ListLink from "./ListLink"

export default function Layout({ activePage }) {
  return (
    <div className={"flex justify-left w-full"}>
      {/* <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
        <h3 style={{ display: `inline` }}>igobyjude</h3>
      </Link> */}
    </div>
  )
}
