import React from "react"
import * as Icon from "react-feather"
import SafeAnchor from "./SafeAnchor"

export default function Socials({ classes, subClasses }) {
  const instagramLink = "https://www.instagram.com/igobyjude/"
  const twitterLink = "https://twitter.com/igobyjude"
  const facebookLink = "https://www.facebook.com/igobyjude"

  return (
    <div className={"flex flex-col justify-between " + classes}>
      <SafeAnchor
        linkUrl={instagramLink}
        classes={`flex justify-center sm:justify-center md:justify-left`}
        subClasses={subClasses}
      >
        <Icon.Instagram />
        <p className={"pl-1"}>igobyjude</p>
      </SafeAnchor>
      <SafeAnchor
        linkUrl={twitterLink}
        classes={`flex justify-center sm:justify-center md:justify-left`}
        subClasses={subClasses}
      >
        <Icon.Twitter />
        <p className={"pl-1"}>@igobyjude</p>
      </SafeAnchor>
      <SafeAnchor
        linkUrl={facebookLink}
        classes={`flex justify-center sm:justify-center md:justify-left`}
        subClasses={subClasses}
      >
        <Icon.Facebook />
        <p className={"pl-1"}>igobyjude</p>
      </SafeAnchor>
    </div>
  )
}
